<template>
  <div class="relative">
    <div
      v-if="label"
      class="gap-1 flex flex-row"
    >
      <div class="space-y-1 pb-2">
        <label
          v-if="label"
          class="font-medium"
          :for="inputId"
        >
          <div class="flex flex-row items-center gap-2">
            <slot name="label-icon"> </slot>
            {{ label }}
          </div>
        </label>
        <span
          v-if="subLabel"
          class="block text-bb-text-secondary text-sm font-regular"
        >
          {{ subLabel }}
        </span>
      </div>
      <new-tooltip
        v-if="tooltip"
        direction="bottom-start"
        :theme="tooltipTheme"
      >
        <template #content>
          {{ tooltipText }}
        </template>
      </new-tooltip>
    </div>

    <!-- Input Field -->
    <div
      v-click-outside="closeDropdown"
      class="relative"
      @click="handleInputFocus($event)"
    >
      <input
        :id="inputId"
        v-model="searchInput"
        :type="inputType"
        :name="inputName"
        :placeholder="placeholder"
        :disabled="disabled"
        autocomplete="off"
        :class="inputWrapperClasses"
        class="text-input"
        @blur="handleInputBlur"
        @keydown.enter.prevent="keydownToSubmit"
        @keydown.down.prevent="increment"
        @keydown.up.prevent="decrement"
      />
      <div
        v-if="hasPrefixIcon"
        class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
        :class="{ 'opacity-50': disabled }"
      >
        <component :is="selectedItem?.icon" />
      </div>
      <ic-chevron
        :direction="isDropdownVisible ? 'up' : 'down'"
        class="absolute right-0 top-0 m-2 cursor-pointer"
        :class="{ 'opacity-25 pointer-events-none': disabled }"
      />
    </div>

    <!-- Feedback Message -->
    <feedback-message
      v-if="!hideFeedback"
      :message="errorMessage"
      class="error-message"
    />

    <!-- Options List -->
    <ul
      v-if="isDropdownVisible"
      class="search-input z-50 w-full absolute rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      :class="openBelow ? 'mt-2' : 'bottom-10 mb-2'"
    >
      <div class="overflow-auto dropdown-scrollbar max-h-48 rounded">
        <!-- Grouped Options -->
        <template v-if="grouped">
          <div
            v-for="(group, groupIndex) in filteredOptions"
            :key="group.label"
          >
            <li class="text-bb-text-default px-4 py-2 text-sm font-medium bg-neutral-0">
              {{ group.label }} ({{ group.options.length }})
            </li>
            <ul>
              <li
                v-for="(option, optionIndex) in group.options"
                :key="option.value"
                class="text-bb-text-default px-4 py-2 text-sm cursor-pointer flex items-center w-full"
                :class="{
                  'bg-bb-secondary-purple text-bb-text-default':
                    selectedIndex === getOptionIndex(groupIndex, optionIndex),
                }"
                @mouseenter="selectedIndex = getOptionIndex(groupIndex, optionIndex)"
                @mouseleave="selectedIndex = ''"
                @click="clickToSelect(option)"
              >
                <div class="flex items-center">
                  <p
                    class="truncate font-normal"
                    :class="optionsSuffix || option.suffix ? truncateWidth : optionsMaxWidth"
                  >
                    {{ option.label }}
                  </p>
                  <div
                    v-if="optionsSuffix && option.suffix"
                    class="text-bb-brand-purple text-sm flex items-center"
                  >
                    <component :is="suffixIcon" />
                    <p class="capitalize">{{ optionsSuffix }}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </template>

        <!-- Ungrouped Options -->
        <template v-else>
          <li
            v-for="(option, index) in filteredOptions"
            :key="option.value"
            :data-is-selected="selection && selection.value === option.value"
            class="px-4 py-2 text-sm cursor-pointer flex items-center w-full"
            :class="{
              'bg-bb-secondary-purple text-bb-text-default': index === selectedIndex,
              'pointer-events-none text-bb-disabled-gray': option?.disabled,
            }"
            @mouseenter="selectedIndex = index"
            @mouseleave="selectedIndex = ''"
            @click="clickToSelect(option)"
          >
            <div class="flex items-center">
              <div
                v-if="hasPrefixIcon"
                class="mr-2"
                :class="{ 'opacity-25': option?.disabled }"
              >
                <component :is="option?.icon" />
              </div>
              <p
                class="truncate"
                :class="optionsSuffix || option.suffix ? truncateWidth : optionsMaxWidth"
              >
                {{ option.label }}
              </p>
              <div
                v-if="optionsSuffix && option.suffix"
                class="text-bb-brand-purple text-sm font-medium flex items-center"
              >
                <component :is="suffixIcon" />
                <p class="capitalize">{{ optionsSuffix }}</p>
              </div>
            </div>
          </li>
        </template>
      </div>

      <!-- Loading and No Matching Results Messages -->
      <div
        v-if="isLoading"
        class="max-h-48 rounded"
      >
        <div class="h-48 w-full flex justify-center align-center px-4">
          <p class="text-sm text-bb-text-default pt-16">Loading...</p>
        </div>
      </div>
      <div
        v-if="filteredOptions.length === 0 && !isLoading"
        class="max-h-48 rounded"
      >
        <div class="h-48 w-full flex justify-center align-center px-4">
          <p class="text-sm text-bb-text-default pt-16">There are no matching results...</p>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import FeedbackMessage from '@/components/base/FeedbackMessage'
import IcStars from '@/components/icon/brightbid/ic-stars.vue'
import IcChevron from '@/components/icon/ic-chevron'
import NewTooltip from '@/components/alert/NewTooltip'
import SolidBrightbidLogo from '@/components/icon/brightbid/solid-brightbid-logo.vue'
import GoogleGIcon from '@/components/icon/brightbid/google-g-icon.vue'
import IcPmax from '@/components/icon/ic-pmax.vue'

export default {
  name: 'SearchInput',
  components: { FeedbackMessage, IcChevron, NewTooltip, IcStars, SolidBrightbidLogo, GoogleGIcon, IcPmax },
  props: {
    inputId: {
      type: [String, Number],
      required: true,
    },
    inputName: {
      type: [String, Number],
      default: 'default-input-name',
    },
    inputType: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: null,
    },
    subLabel: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    value: {
      type: [Object, Array, String, Number, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    hideFeedback: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionsMaxWidth: {
      type: String,
      default: 'max-w-64',
    },
    truncateWidth: {
      type: String,
      default: 'w-46',
    },
    dynamicPlaceholder: {
      type: Boolean,
      default: false,
    },
    optionsSuffix: {
      type: String,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: 'Tooltip text',
    },
    tooltipTheme: {
      type: String,
      default: 'sm',
    },
    grouped: {
      type: Boolean,
      default: false,
    },
    hasPrefixIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasFocus: false,
      isDropdownVisible: false,
      searchInput: null,
      selectedIndex: 0,
      selectedItem: null,
      openBelow: false,
    }
  },
  computed: {
    inputWrapperClasses() {
      if (this.disabled) {
        return {
          disabled: true,
          'has-prefix-icon': this.hasPrefixIcon,
          'opacity-50': true,
        }
      }
      return {
        focus: this.hasFocus,
        success: this.success,
        error: this.error || !!this.errorMessage,
        'has-prefix-icon': this.hasPrefixIcon,
      }
    },
    selection() {
      if (this.grouped) {
        const [groupIndex, optionIndex] = this.selectedIndex.split('-')
        if (this.filteredOptions[groupIndex] && this.filteredOptions[groupIndex].options[optionIndex]) {
          return this.filteredOptions[groupIndex].options[optionIndex]
        }
      } else {
        if (this.filteredOptions[this.selectedIndex]) return this.filteredOptions[this.selectedIndex]
      }
      return false
    },
    filteredOptions() {
      if (!this.searchInput) return this.options
      if (this.grouped) {
        return this.options
          .map(group => {
            return {
              ...group,
              options: group.options.filter(
                option =>
                  option.label &&
                  typeof option.label === 'string' &&
                  option.label.toLowerCase().includes(this.searchInput.toLowerCase()),
              ),
            }
          })
          .filter(group => group.options.length > 0)
      } else {
        return this.options.filter(
          option =>
            option.label &&
            typeof option.label === 'string' &&
            option.label.toLowerCase().includes(this.searchInput.toLowerCase()),
        )
      }
    },
    suffixIcon() {
      switch (this.optionsSuffix) {
        case 'autopilot':
          return 'ic-stars'
        default:
          return null
      }
    },
  },
  watch: {
    errorMessage() {
      if (this.errorMessage) {
        this.hasFocus = false
      }
    },
    options(value) {
      if (value.length === 0) {
        this.searchInput = null
        this.selectedItem = null
      }
    },
  },
  mounted() {
    // set the default value
    this.searchInput = this.value?.label || ''
    if (this.dynamicPlaceholder) this.searchInput = this.value?.text || ''
    this.selectedItem = this.value || { label: '', value: '' }
  },
  methods: {
    handleInputFocus(event) {
      if (this.isDropdownVisible) {
        this.closeDropdown()
        return
      }

      const element = document.querySelector('.table-enclosure')
      const dropdown = event.target.getBoundingClientRect()
      if (!element) this.openBelow = true
      else {
        this.openBelow = Math.abs(dropdown.bottom - element.getBoundingClientRect().bottom) > 200
      }

      this.hasFocus = !this.isDropdownVisible && !this.errorMessage
      this.isDropdownVisible = true
      this.searchInput = '' // Resetting the input field @focus will result in the reset of the filtered options.
    },
    handleInputBlur() {
      if (!this.selectedIndex) this.$emit('blur')
    },
    closeDropdown() {
      if (!this.selectedItem) {
        this.isDropdownVisible = false
        this.hasFocus = false
        return
      }
      if (this.searchInput !== this.selectedItem?.value) {
        this.searchInput = this.dynamicPlaceholder ? this.selectedItem.text : this.selectedItem.label
      }
      this.isDropdownVisible = false
      this.hasFocus = false
    },
    clickToSelect(option) {
      this.selectedItem = option
      this.submitSelectedItem()
    },
    keydownToSubmit() {
      this.selectedItem = this.selection
      this.submitSelectedItem()
    },
    submitSelectedItem() {
      this.searchInput = this.selectedItem.text
      this.closeDropdown()
      this.$emit('select-item', this.selectedItem, this.inputId)
    },
    increment() {
      if (this.grouped) {
        const [groupIndex, optionIndex] = this.selectedIndex.split('-').map(Number)
        const group = this.filteredOptions[groupIndex]
        if (optionIndex + 1 < group.options.length) {
          this.selectedIndex = `${groupIndex}-${optionIndex + 1}`
        } else if (groupIndex + 1 < this.filteredOptions.length) {
          this.selectedIndex = `${groupIndex + 1}-0`
        } else {
          this.selectedIndex = '0-0'
        }
      } else {
        const length = this.options.length
        if (this.selectedIndex + 1 < length) {
          this.selectedIndex++
        } else {
          this.selectedIndex = 0
        }
      }
    },
    decrement() {
      if (this.grouped) {
        const [groupIndex, optionIndex] = this.selectedIndex.split('-').map(Number)
        if (optionIndex - 1 >= 0) {
          this.selectedIndex = `${groupIndex}-${optionIndex - 1}`
        } else if (groupIndex - 1 >= 0) {
          const prevGroup = this.filteredOptions[groupIndex - 1]
          this.selectedIndex = `${groupIndex - 1}-${prevGroup.options.length - 1}`
        } else {
          const lastGroup = this.filteredOptions[this.filteredOptions.length - 1]
          this.selectedIndex = `${this.filteredOptions.length - 1}-${lastGroup.options.length - 1}`
        }
      } else {
        const length = this.options.length
        if (this.selectedIndex - 1 >= 0) {
          this.selectedIndex--
        } else {
          this.selectedIndex = length - 1
        }
      }
    },
    getOptionIndex(groupIndex, optionIndex) {
      return `${groupIndex}-${optionIndex}`
    },
  },
}
</script>

<style scoped lang="scss">
.text-input {
  width: 100%;
  height: 40px;
  font-size: 14px;
  padding: 8px;
  padding-right: 40px;
  background: white;
  border: $neutral-100 solid 1px;
  border-radius: 8px;
  color: $bb-text-headers !important;
}

.text-input.has-prefix-icon {
  padding-left: 40px;
}

.focus {
  outline: $bb-brand-purple solid 2px;
  color: $bb-text-default !important;
}

.error {
  border: $bb-error solid 1px;
  &:focus {
    border: $neutral-100 solid 1px;
    outline: $bb-error solid 2px;
  }
}

.error-message {
  position: absolute;
  bottom: -23px;
}
</style>
